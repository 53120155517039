@use "../../App";
// @use "sass:math";
.pinkyEffect {
  // content: 'a';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 2;
  box-shadow: 0 0 10vh App.$glow-variant inset;
}

.CounterContainer {
  position: absolute;
  display: flex;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.15s linear;
  z-index: 2;

  //Image
  .ImgContainer {
    overflow: hidden;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px App.$dark-neutral-variant;
    transition: all 0.2s linear;
  }
  .personImg {
    height: 100%;
    transition: 0.12s linear;
  }
  .personImg:hover {
    scale: 1.12;
  }

  //Info
  .info {
    margin: 7.5px 0 0 0;
    color: white;
    font-size: 16px;
    font-weight: 800;
    text-shadow: 1.3px 1.3px App.$dark-neutral-color;
    transition: all 0.2s linear;
  }

  //Count
  .count {
    transform: translateY(-3vh);
    width: 300px;
    // margin: 0 20px 0 20px;
    text-align: center;

    .Days {
      color: App.$primary-color;
      font-size: 50px;
      width: 100%;
      height: 65px;
      font-weight: 1000;
      text-shadow: 2px 1.5px App.$secondary-variant;
      cursor: pointer;
    }
    .FullTime {
      color: App.$primary-color;
      font-size: 35px;
      width: 100%;
      height: 65px;
      font-weight: 900;
      text-shadow: 1.1px 0.5px App.$secondary-variant;
      cursor: pointer;
    }
    .Times {
      color: App.$primary-color;
      font-size: 28px;
      font-weight: 800;
      text-shadow: 1.1px 0.5px App.$secondary-variant;
    }
    .heart {
      margin: 18px 0 18px 0;
      color: App.$accent-variant;
      stroke-width: 8;
      stroke: App.$dark-neutral-color;
      font-size: 50px;
      cursor: pointer;
      animation: pump 1.15s ease-in infinite;
      z-index: 2;
    }

    .FromToDate {
      color: white;
      font-size: 15px;
      font-weight: 800;
      text-shadow: 1.3px 1.3px App.$dark-neutral-color;
    }
  }
}

.animatedHeart {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: App.$accent-variant;
  opacity: 0;
  z-index: 3;
}
.left {
  animation: animatedHeartFly1 2.8s linear;
}
.right {
  animation: animatedHeartFly 2.8s linear;
}

@media only screen and (max-width: 600px) {
  .CounterContainer {
    position: absolute;
    top: 30px;
    transform: translate(-50%, 0);
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: calc(800px * 0.8);

    .ImgContainer {
      height: 137px;
      width: 137px;
    }

  }
}
@keyframes pump {
  0% {
    scale: 1;
  }
  75% {
    scale: 1.1;
  }
  80% {
    scale: 1;
  }
  85% {
    scale: 1.1;
  }
  100% {
    scale: 0.9;
  }
}
@keyframes animatedHeartFly1 {
  from {
    opacity: 1;
  }
  to {
    top: -30px;
    opacity: 0;
    scale: 1.6;
    transform: translateX(-40px);
  }
}
@keyframes animatedHeartFly {
  from {
    opacity: 1;
  }
  to {
    top: -30px;
    opacity: 0;
    scale: 1.6;
    transform: translateX(40px);
  }
}
