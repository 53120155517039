@use "../../App";

.food {
  position: absolute;
  z-index: 5;
  color: App.$primary-color;
  font-weight: 550;
  font-size: 20px;
  text-shadow: 0 0 5px App.$dark-primary-color;
  text-transform: uppercase;
}
// .randomBtn {
//   position: absolute;
//   top: 36%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   z-index: 4;
//   font-size: 20px;
//   font-weight: 1000;
//   height: 60px;
//   text-transform: uppercase;
// }
.randomActionBtn {
  position: absolute;
  top: 50%;
  left: 49%;
  transform: translate(-50%, -50%);
  z-index: 4;

  .Action {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    height: 35vh;
    transition: all 0.22s linear;
    cursor: pointer;
    filter: drop-shadow(0 0 2px App.$primary-color);
  }

  .ActionGlow {
    animation: doActionGlow 1.5s ease-in-out infinite;
  }

  .Action1 {
    opacity: 1;
  }

  .Action2 {
    opacity: 0;
  }
}
.foodResult {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  text-shadow: 0 0 5px App.$dark-primary-color;
  color: App.$primary-color;
  font-size: 25px;
  font-weight: 550;
  text-transform: uppercase;
  animation: glow 2s ease infinite;
}
.deleteResult {
  padding: 3px 0 0 0;
  margin: 0 0 0 5px;
  font-size: 12px;
  font-weight: 550;
}
.randomActionBtn:hover {
  .Action1 {
    opacity: 0;
  }

  .Action2 {
    opacity: 1;
  }
}
.editButton {
  position: absolute;
  z-index: 5;
  bottom: 28%;
  left: 48.3%;
  transform: translate(-50%, 0);
  color: App.$light-neutral-color;
  font-size: 20px;
  transition: all 0.12s linear;
  cursor: pointer;
}
.editButton:hover {
  color: App.$secondary-variant;
}

.addBtn {
  cursor: pointer;
  transition: all 0.12s linear;
  margin: 0 0 0 5px;
}
.addBtn:hover {
  color: App.$secondary-color;
}
.deleteBtn {
  color: red;
  cursor: pointer;
}

.randomPlacesCardContainer {
  margin: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 5;
  animation: fadeIn 0.7s ease-in-out;
}

.card {
  margin: 3px 3px 3px 3px;
  width: 170px;
  padding: 0;
  font-size: 15px;
  font-weight: 550;
  color: App.$dark-primary-color;
  text-align: center;
  text-transform: uppercase;
}
.randomFoodRollBtn {
  position: absolute;
  // font-size: 30px;
  top: 33vh;
  left: 51.5%;
  transform: translate(-50%, 0);
  z-index: 5;
  animation: fadeIn 0.7s ease-in-out;
}

.randomPlacesSettings {
  height: 100%;
  margin: 0 0 0 2px;
  color: white;
  // padding: 0;
}

.ResultContainer {
  position: absolute;
  top: 41vh;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 0);
  text-align: center;
  z-index: 5;
  color: App.$primary-color;
  text-shadow: 0 0 7px App.$dark-primary-color;


  .resultTitle {
    font-size: 23px;
    font-weight: 600;
  }
  .resultContent {
    font-size: 17px;
    font-weight: 570;
  }
}

.removeBtn {
  color: red;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}


@media only screen and (max-width: 600px) {
  .card {
    width: calc(100vw/3.2);
    font-size: 10px;
  }
  .randomFoodRollBtn {
    top: 31vh;
    left: 52%;
  }
  .food {
    font-weight: 550;
    font-size: 16px;
  }
}
@keyframes doActionGlow {
  0% {
    filter: drop-shadow(0 0 2px App.$primary-color);
  }
  50% {
    filter: drop-shadow(0 0 20px App.$primary-color);
  }
  100% {
    filter: drop-shadow(0 0 2px App.$primary-color);
  }
}
@keyframes glow {
  0% {
    font-weight: 550;
    text-shadow: 0 0 5px App.$dark-primary-color;
  }
  50% {
    font-weight: 600;
    text-shadow: 0 0 20px App.$dark-primary-color;
  }
  100% {
    font-weight: 550;
    text-shadow: 0 0 5px App.$dark-primary-color;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}
