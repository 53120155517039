@use "../../App";

.Navbar {
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  z-index: 5;
}

.NavBtnContainer {
  display: flex;
  list-style: none;
  padding: 0;

  .navBtn,
  a {
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    text-align: center;
    width: 100px;
    color: App.$light-neutral-color;
    font-weight: 550;
    font-size: 18px;
    text-decoration: none;
    transition: all 0.12s linear;
    cursor: pointer;
  }
  .navBtn:hover,
  a:hover {
    color: App.$secondary-color;
    text-shadow: 0 0 7px App.$dark-primary-color;
  }

  .dropdown {
    position: absolute;
    list-style: none;
    bottom: -115%;
    left: 0;
    background-color: rgba(85, 85, 85, 0.3);
    padding: 0;
    border-radius: 7px;
    display: none;
    opacity: 0;
    transition: all 0.22s linear;

    .dropdownItem {
      height: 20px;
      padding: 5px 0 8px 0;
    }
  }

  .navBtn:hover > .dropdown,
  .dropdown:hover {
    display: block;
    opacity: 1;
  }

  .navIcon {
    font-size: 15px;
    font-weight: 550;
    margin: 3px 0 0 0;
  }
}

.MenuBtn {
  position: relative;
  display: none;
  top: 15px;
  right: 0;
  height: 20px;
  width: 30px;
  cursor: pointer;
  transition: all 0.11s linear;

  span {
    background-color: App.$secondary-color;
    height: 2px;
    width: 100%;
    position: absolute;
    transition: all 0.22s;
  }
  .open:nth-child(1) {
    transform: rotate(-45deg) translate(-9px, 6px);
    top: 0;
  }
  .open:nth-child(2) {
    opacity: 0;
    top: calc(100%/2);
  }
  .open:nth-child(3) {
    transform: rotate(45deg) translate(-8px, -5px);
    top: 100%;
  }
  .close:nth-child(1) {
    top: 0;
  }
  .close:nth-child(2) {
    top: calc(100%/2);
  }
  .close:nth-child(3) {
    top: 100%;
  }
}
.MenuBtn:hover {
  filter: drop-shadow(0 0 12px App.$dark-primary-color);
}

@media only screen and (max-width: 600px) {
  .MenuBtn {
    position: absolute;
    right: 3.5vw;
    display: block;
  }
  .Navbar {
    height: auto;
    height: 47px;
  }
}
