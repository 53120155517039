//font
@font-face {
  font-family: Quicksand;
  src: url(./Assets/Fonts/Quicksand/Quicksand-VariableFont_wght.ttf);
}

//Colors variables
//Main colors
$primary-color: #ffd1dc;
$secondary-color: #daa29e;
$dark-primary-color: #aa1373;
$dark-neutral-color: #333333;
$light-neutral-color: #ffffff;
//Additional colors
$primary-variant: #eed5d2;
$secondary-variant: #f3b7b5;
$glow-variant: #f49ad3;
$dark-neutral-variant: #555555;
$light-neutral-variant: #cccccc;
$accent-variant: #dc143c;

* {
  font-family: Quicksand;
  font-weight: 500;
  user-select: none;
}

*::selection {
  background-color: $primary-variant;
}

.App {
  position: relative;
  overflow: hidden;
  background: $primary-variant;
  overscroll-behavior: smooth;
  height: 100vh;
  width: 100vw;
}
.DaysCounterBG {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.6;
  filter: brightness(0.6);
  z-index: 1;
}

.Cover {
  position: absolute;
  background: black;
  height: 100vh;
  width: 100vw;
  z-index: 6;
  opacity: 0.87;
  text-align: center;
  cursor: pointer;

  .title {
    position: absolute;
    top: 40%;
    left:50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 580;
    font-size: 20px;
    width: 100%;
  }
  .content {
    position: absolute;
    top: 45%;
    left:50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 580;
    font-size: 15px;
    width: 100%;
  }

  .clickSpotAction {
    position: absolute;
    bottom: 20%;
    left:50%;
    transform: translate(-50%, -50%);
    color: white;
    font-weight: 550;
    font-size: 15px;
    width: 100%;
    animation: Glow 3.5s linear infinite;
  }
}

.devices {
  color: white;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 5;
  opacity: 0.5;
  font-size: 10px;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 1500px) {
  .DaysCounterBG {
    height: 100%;
  }
}
@media only screen and (min-width: 1501px) {
  .DaysCounterBG {
    width: 100%;
  }
}

@keyframes Glow {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}